.cookie-policy {
  position: fixed;
  bottom: 0px;
  left: 0px;
  text-indent: 110px;
  background-color: #f7f7f7cc;
}

.cookie-policy a {
  text-indent: 10px;
}
