.mainHeader {
  background-color: #FFFFFF !important
}

.mainMenuIcon {
  background-color: #1a8fff;
  max-height: 39px;
  padding-left: 10px;
  margin-left: -23px !important;
}

.mainHeaderSearch {
  border: 1.02px solid #70707040;
  border-radius: 7px;
}

.mainNavTab {
  color: '#1a8fff' !important;
}
