// Theme colors
$primary: #1891DC;
$primary2: #3BC062;
$primary2Hover: #2B8C49;
$secondary: #0DB0B7;
$default: #C1C1C1;

// White
$white: #FFFFFF;

// Text Colors
$primary-Text: #363D46;
$secondary-Text: #7E8791;
$text-white: #FFFFFF;

// Booking Status
$active : #9e9e9e;
$canceled : #f44336;
$completed : #4caf50;
$expired : #FF9CA1;
$handle: #ffe119;
$ongoing: #d8d8d8; 
// Employees
$grey: #a9a9a9;
$maroon: #800000;
$olive: #808000;
$teal: #469990;
$navy: #000075;
$black: #000000;
$red: #e6194B;
$orange: #f58231;
$yellow: #ffe119;
$green: #3cb44b;
$cyan: #42d4f4;
$purple: #911eb4;

// components View only
.primary {
    background-color: $primary !important;
}
.secondary {
    background-color: $secondary !important;
}
.default {
    background-color: $default !important;
}
.primary-text {
    color: $primary-Text !important;
}
.secondary-text {
    color: $secondary-Text !important;
}
.textWhite {
    color: $text-white !important;
}
.expired {
    background-color: $expired !important;
    color: $black !important;
}
.handle {
    background-color: $handle !important;
    color: $black !important;
}
.ongoing {
    background-color: $ongoing !important;
    color: $black !important;
}
.active {
    background-color: $active !important;
    color: $text-white !important;
}
.canceled {
    background-color: $canceled !important;
    color: $text-white !important;
}
.completed {
    background-color: $completed !important;
    color: $text-white !important;
}
.grey {
    background-color: $grey;
}
.maroon {
    background-color: $maroon;
}
.olive {
    background-color: $olive;
}
.teal {
    background-color: $teal;
}
.navy {
    background-color: $navy;
}
.black {
    background-color: $black;
}
.red {
    background-color: $red;
}
.orange {
    background-color: $orange;
}
.yellow {
    background-color: $yellow;
}
.green {
    background-color: $green;
}
.cyan {
    background-color: $cyan;
}
.purple {
    background-color: $purple;
}
