@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Open Sans', sans-serif;
}

.pointer {
  cursor: pointer;
}

.colors_primary__1sio4{background-color:#1891DC !important}.colors_secondary__edwwI{background-color:#0DB0B7 !important}.colors_default__3mWCk{background-color:#C1C1C1 !important}.colors_primary-text__33QQV{color:#363D46 !important}.colors_secondary-text__KYPHZ{color:#7E8791 !important}.colors_textWhite__2Sh2z{color:#fff !important}.colors_expired__2TJ1b{background-color:#FF9CA1 !important;color:#000 !important}.colors_handle__1xHS3{background-color:#ffe119 !important;color:#000 !important}.colors_ongoing__153Z4{background-color:#d8d8d8 !important;color:#000 !important}.colors_active__3fgRw{background-color:#9e9e9e !important;color:#fff !important}.colors_canceled__2YXkU{background-color:#f44336 !important;color:#fff !important}.colors_completed__fpRFu{background-color:#4caf50 !important;color:#fff !important}.colors_grey__ynXvE{background-color:#a9a9a9}.colors_maroon__2Z3Vl{background-color:maroon}.colors_olive__3wN1m{background-color:olive}.colors_teal__3N2Fi{background-color:#469990}.colors_navy__3qRnN{background-color:#000075}.colors_black__ji0Zr{background-color:#000}.colors_red__3-vWj{background-color:#e6194B}.colors_orange__29Jco{background-color:#f58231}.colors_yellow__2SmRz{background-color:#ffe119}.colors_green__cZ-lR{background-color:#3cb44b}.colors_cyan__1z8Oy{background-color:#42d4f4}.colors_purple__24Yd9{background-color:#911eb4}

.cookie-policy {
  position: fixed;
  bottom: 0px;
  left: 0px;
  text-indent: 110px;
  background-color: #f7f7f7cc;
}

.cookie-policy a {
  text-indent: 10px;
}

.navbar_primary__3_HfQ{background-color:#1891DC !important}.navbar_secondary__3pUUl{background-color:#0DB0B7 !important}.navbar_default__3KSy2{background-color:#C1C1C1 !important}.navbar_primary-text__1ycd6{color:#363D46 !important}.navbar_secondary-text__3un7K{color:#7E8791 !important}.navbar_textWhite__-TnKZ{color:#fff !important}.navbar_expired__1qEyE{background-color:#FF9CA1 !important;color:#000 !important}.navbar_handle__2k9rs{background-color:#ffe119 !important;color:#000 !important}.navbar_ongoing__uoDxr{background-color:#d8d8d8 !important;color:#000 !important}.navbar_active__2aLSC{background-color:#9e9e9e !important;color:#fff !important}.navbar_canceled__1q_YX{background-color:#f44336 !important;color:#fff !important}.navbar_completed__2ch1R{background-color:#4caf50 !important;color:#fff !important}.navbar_grey__YlYfb{background-color:#a9a9a9}.navbar_maroon__2EoA0{background-color:maroon}.navbar_olive__2fz-w{background-color:olive}.navbar_teal__2XyiL{background-color:#469990}.navbar_navy__28Hyr{background-color:#000075}.navbar_black__opxuY{background-color:#000}.navbar_red__20AiN{background-color:#e6194B}.navbar_orange__5tgYB{background-color:#f58231}.navbar_yellow__3oxt6{background-color:#ffe119}.navbar_green__2i9Sz{background-color:#3cb44b}.navbar_cyan__3Kuyn{background-color:#42d4f4}.navbar_purple__3unj0{background-color:#911eb4}.navbar_Appbar__3ifRv{background-color:#fff !important;border-bottom:1px solid #C1C1C1 !important;color:#000 !important}.navbar_MenuText__3OWbP{color:#fff !important;-webkit-align-self:center !important;align-self:center !important}.navbar_Paper__3XpjJ{height:48px !important;width:104px !important;margin-right:16px !important;background-color:#3BC062 !important;display:-webkit-flex !important;display:flex !important;-webkit-justify-content:center !important;justify-content:center !important;margin-right:24px !important;cursor:pointer !important}.navbar_Paper__3XpjJ:hover{background-color:#2B8C49 !important}

.mainHeader_mainHeader__3s2NN{background-color:#FFFFFF !important}.mainHeader_mainMenuIcon__1KT4N{background-color:#1a8fff;max-height:39px;padding-left:10px;margin-left:-23px !important}.mainHeader_mainHeaderSearch__2t247{border:1.02px solid #70707040;border-radius:7px}.mainHeader_mainNavTab__2WeSt{color:'#1a8fff' !important}

