@import './colors.module.scss';

.Appbar {
    background-color: $white !important;
    border-bottom: 1px solid $default !important;
    color: $black !important;
}
.MenuText {
    color: $white !important;
    align-self: center !important;
}
.Paper {
    height: 48px !important;
    width: 104px !important;
    margin-right: 16px !important; 
    background-color: $primary2 !important;
    display: flex !important;
    justify-content: center !important;
    margin-right: 24px !important;
    cursor: pointer !important;
}
.Paper:hover {
    background-color: $primary2Hover !important;
}